import React from 'react';
import logo from './logo.svg';
import * as Icons from '@wix/wix-ui-icons-common';
import './App.css';
import { Button, EmptyState, Page, Image, TextButton, WixDesignSystemProvider, Text, Box, Layout, Cell, Card, Tabs, Loader } from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from 'react-gtm-ts';

function App() {

  const [activeTabId, setActiveTabId] = React.useState(1);

  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=192982cd-209f-4e3b-8895-68936716750f&redirectUrl=https://www.platform.verify.certifiedcode.us/_functions/@certifiedcode/base-backend/auth`;
  }

  if (!!token) {
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }
  
  const EmptyStateBlock = () => {
    return <EmptyState
      title="Start setting up 2FA with Wix Memnbers Area"
      subtitle="With Verify, you can add an extra layer of security to your members login. You can choose to require 2FA for all members or only for members who have access to specific pages."
      theme="page"
    >
      <TextButton prefixIcon={<Icons.Add />}>Start Now</TextButton>
    </EmptyState>
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page>
        <Page.Header
          title="Verify: 2FA for Members Login"
          subtitle="Create and manage categories for your events."
          actionsBar={
            <Box direction="vertical" gap="SP1">
              <Button skin="premium" prefixIcon={<Icons.PremiumFilled />}>Upgrade</Button>
              <Text size="tiny" skin="premium">Get more 2FA sessions</Text>
            </Box>
          }
        />
        <Page.Content>
          {EmptyStateBlock()}
          <Page.Tail>
            <Tabs
              items={[
                { id: 1, title: 'Manage Rules' },
                { id: 2, title: 'Recent Login' },
              ]}
              type="compactSide"
              activeId={activeTabId}
              onClick={(tab: any) => setActiveTabId(tab.id)}
            />
          </Page.Tail>
          <Page.Content>
            <Layout>
            
            </Layout>
          </Page.Content>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  )
}

export default App;
